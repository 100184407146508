import React from 'react'
import { SEO, Layout } from '@global'
import { WriteAReviewCallout } from '@layouts'

const WriteAReviewPage = () => {
  return (
    <div className="relative">
      <SEO title="Write a review" />
      <Layout>
        <WriteAReviewCallout />
      </Layout>
    </div>
  )
}

export default WriteAReviewPage
